<template>
  <div class="data-manage">
    <div class="main-content" :class="showSearchDrawer ? 'active' : ''">
      <div class="page-title clearfix">
        <div class="float-left">
          <span>{{ $t("nav.MRI_analysis") }}</span>
          <span class="total-num">{{ totalCount }}</span>
        </div>
        <div class="float-right">
          <el-radio-group
            v-model="modality"
            @change="changeModality"
            size="small"
          >
            <el-radio-button label="all">{{
              $t("project.all")
            }}</el-radio-button>
            <el-radio-button
              v-for="item in appModality"
              :key="item"
              :label="item"
              >{{ item }}</el-radio-button
            >
          </el-radio-group>
        </div>
      </div>
      <div class="data-content">
        <div class="search-wrapper">
          <span class="search-btn" @click.stop="handleCurrentSearch">{{
            $t("search")
          }}</span>
          <el-form
            :inline="true"
            :model="searchForm"
            class="search-form"
            size="small"
          >
            <el-form-item :label="$t('authority.analysis')">
              <el-select
                v-model="app_id"
                @change="handleAppChange"
                clearable
                placeholder=""
              >
                <el-option
                  v-for="item in appList"
                  :key="item.id"
                  :label="item.app_name"
                  :value="item.app_id"
                >
                  <!-- <div class="float-start">
                    <strong>{{ item.app_name }}</strong>
                  </div>
                  <div class="float-end">
                    <small class="bg-primary text-white bg-span">{{
                      item.modality
                    }}</small>
                    <dfn
                      v-if="item.multimodality == 1 && item.app_json.subtypes"
                    >
                      <i v-for="(sub, i) in item.app_json.subtypes" :key="i"
                        ><small
                          v-if="sub.modality != item.modality"
                          class="bg-primary text-white bg-span"
                          >{{ sub.modality }}</small
                        ></i
                      >
                    </dfn>
                    <dfn v-if="item.series && item.series != ''">
                      <small
                        v-for="seria in item.series"
                        :key="seria"
                        class="bg-success bg-span text-white"
                        >{{ seria }}</small
                      ></dfn
                    >
                    <dfn v-for="(sub, i) in item.app_json.subtypes" :key="i">
                      <i v-for="(s, j) in sub.series" :key="j"
                        ><small class="bg-secondary bg-span text-white">{{
                          s
                        }}</small></i
                      >
                    </dfn>
                  </div> -->
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('project.series')">
              <el-select
                v-model="searchForm.series"
                @change="handleSeriesChange"
                clearable
                placeholder=""
              >
                <el-option
                  v-for="item in seriesList"
                  :key="item.id"
                  :label="item.series_type"
                  :value="item.series_type"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label=" ">
              <el-input
                v-model="searchForm.search"
                clearable
                :placeholder="$t('analyse.fuzzy_search')"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="tabel-content">
          <el-table
            ref="multipleTable"
            :data="dataList"
            :empty-text="$t('no_data')"
            stripe
            style="width: 100%"
            header-row-class-name="thead-dark"
          >
            <el-table-column
              prop="subject_number"
              :label="$t('_data.p_name')"
              width="180"
            >
              <template v-slot="scope">
                <router-link
                  :to="{
                    name: 'subjectDetail',
                    query: {
                      id: scope.row.subject_id,
                      from: 'analyselist',
                      offset: currentPage,
                    },
                  }"
                  >{{ scope.row.subject_number }}</router-link
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="subject_gender"
              :formatter="formatGender"
              :label="$t('gender')"
              width="70"
            ></el-table-column>
            <el-table-column prop="subject_age" :label="$t('age')" width="80">
              <template v-slot="scope">
                <span>
                  {{ scope.row.subject_age }}
                  {{ $t($helper.formatAgeUnit(scope.row.subject_age_unit)) }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="task_name"
              :label="$t('analyse.task_name')"
            ></el-table-column>
            <el-table-column
              prop="date_created"
              :label="$t('s_time')"
            ></el-table-column>
            <el-table-column
              prop="date_update"
              :label="$t('u_time')"
            ></el-table-column>
            <el-table-column
              prop="data_manager"
              :label="
                $t('analyse.data_owner') + '(' + $t('sign.username') + ')'
              "
            >
              <template v-slot="scope">
                <span>
                  {{ scope.row.data_manager }} ({{ scope.row.username }})
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="body_part_examined"
              :label="$t('analyse.body_part')"
            ></el-table-column>
            <el-table-column
              prop="app_name"
              :label="$t('authority.analysis')"
            ></el-table-column>
            <el-table-column prop="status" :label="$t('analyse.status')">
              <template v-slot="scope">
                <span :class="statusColClass(scope.row.status)">{{
                  scope.row.status | formatStatus
                }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('voi_edit')" align="center">
              <template slot-scope="scope">
                <a
                  v-if="scope.row.status == '3' && scope.row.use_voi_editor"
                  target="_blank"
                  :href="
                    url_voiviewer +
                    '?service_url=' +
                    url_adaptorapi +
                    '&backendapi=' +
                    url_backendapi +
                    '&keyid=' +
                    scope.row.task_id +
                    '&app_id=' +
                    scope.row.app_id +
                    '&datatype=brainlabel&subject_number=' +
                    scope.row.subject_number +
                    '&subject_age=' +
                    scope.row.subject_age +
                    '&subject_age_unit=' +
                    scope.row.subject_age_unit +
                    '&subject_gender=' +
                    scope.row.subject_gender +
                    '&language=' +
                    (lang == 'cn' ? 'zh' : lang) +
                    '&dataowner=' +
                    (scope.row.data_ownerid == user.id ? 'true' : 'false') +
                    '&uid=' +
                    scope.row.data_ownerid +
                    '&user=' +
                    user.id +
                    '&image_data=' +
                    scope.row.image_data +
                    '&static_url=' +
                    scope.row.static_url +
                    '&timer=' +
                    timer +
                    '&user_role=' +
                    user.role +
                    '&resultdownload=' +
                    (scope.row.label_image_path && user.role == '0'
                      ? 'true'
                      : 'false') +
                    '&token=' +
                    token +
                    '&suvr=' +
                    (scope.row.app_json.series.includes('AV45')
                      ? 'true'
                      : 'false')
                  "
                  >{{ $t("voi_edit") }}</a
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="subject_number"
              :label="$t('analyse.result')"
            >
              <template v-slot="scope">
                <a
                  v-if="scope.row.url_t1viewer"
                  :href="
                    scope.row.url_t1viewer +
                    '&language=' +
                    lang +
                    '&timer=' +
                    timer
                  "
                  target="_blank"
                  >{{ $t("analyse.viewer") }}</a
                >

                <a
                  v-if="
                    scope.row.url_ptviewer &&
                    scope.row.status === '3' &&
                    scope.row.app_json.series.includes('AV133')
                  "
                  :href="
                    scope.row.url_ptviewer +
                    '&language=' +
                    (lang == 'cn' ? 'zh' : lang) +
                    '&timer=' +
                    timer
                  "
                  target="_blank"
                  >{{ $t("analyse.viewer") }}</a
                >

                <a
                  v-if="scope.row.url_dtiviewer"
                  :href="
                    scope.row.url_dtiviewer +
                    '&language=' +
                    (lang == 'zh' ? 'cn' : lang) +
                    '&subject=' +
                    scope.row.subject_number +
                    '&age=' +
                    scope.row.subject_age +
                    '&age_unit=' +
                    scope.row.subject_age_unit +
                    '&gender=' +
                    scope.row.subject_gender +
                    '&timer=' +
                    timer
                  "
                  target="_blank"
                  >{{ $t("analyse.viewer") }}</a
                >

                <a
                  v-if="scope.row.url_flairviewer"
                  :href="
                    scope.row.url_flairviewer +
                    '&language=' +
                    (lang == 'zh' ? 'cn' : lang) +
                    '&subject=' +
                    scope.row.subject_number +
                    '&age=' +
                    scope.row.subject_age +
                    '&age_unit=' +
                    scope.row.subject_age_unit +
                    '&gender=' +
                    scope.row.subject_gender +
                    '&timer=' +
                    timer
                  "
                  target="_blank"
                  >{{ $t("analyse.viewer") }}</a
                >

                <a
                  v-if="scope.row.url_multimodalityviewer"
                  :href="
                    scope.row.url_multimodalityviewer +
                    '&language=' +
                    (lang == 'zh' ? 'cn' : lang) +
                    '&subject=' +
                    scope.row.subject_number +
                    '&age=' +
                    scope.row.subject_age +
                    '&age_unit=' +
                    scope.row.subject_age_unit +
                    '&gender=' +
                    scope.row.subject_gender +
                    '&timer=' +
                    timer
                  "
                  target="_blank"
                  >{{ $t("analyse.viewer") }}</a
                >

                <a
                  v-if="
                    scope.row.status === '3' &&
                    scope.row.app_json.series.includes('AV45')
                  "
                  class="ms-1"
                  target="_blank"
                  :href="
                    url_backendapi +
                    'analysis/report_page/av45/#/?type=PT&series=av45&tid=' +
                    scope.row.task_id +
                    '&user_id=' +
                    user.id +
                    '&cid=1&keyid=0&requrl=/analysis/petreport/av45api&host=' +
                    url_backendapi +
                    '&service_url=' +
                    url_adaptorapi +
                    '&token=' +
                    token
                  "
                  >{{ $t("project.view_report") }}</a
                >

                <a
                  v-if="
                    scope.row.status === '3' &&
                    scope.row.app_json.series.includes('AV133')
                  "
                  class="ms-1"
                  target="_blank"
                  :href="
                    url_backendapi +
                    'analysis/report_page/petreport/#/?type=PT&series=av133&tid=' +
                    scope.row.task_id +
                    '&cid=1&keyid=0&requrl=/analysis/petviewer/index&host=' +
                    url_backendapi +
                    '&token=' +
                    token
                  "
                  >查看报告</a
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              :label="$t('operate')"
              align="right"
            >
              <template v-slot="scope">
                <el-dropdown>
                  <span class="el-dropdown-link cursor">
                    <i class="fa fa-bars mr-1"></i>
                    <i class="fa fa-angle-down"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item disabled>{{
                      $t("operate_menu")
                    }}</el-dropdown-item>
                    <el-dropdown-item
                      divided
                      v-if="
                        scope.row.status === '3' &&
                        scope.row.download_type.statistic
                      "
                      @click.native="downloadStatistic(scope.row.id)"
                    >
                      <i class="fa fa-download text-success"></i>
                      {{ $t("analyse.download_statistic") }}</el-dropdown-item
                    >
                    <el-dropdown-item
                      v-if="
                        scope.row.status === '3' &&
                        scope.row.download_type.result &&
                        user.role == '0'
                      "
                      @click.native="downloadResult(scope.row.id)"
                    >
                      <i class="fa fa-download text-success"></i>
                      {{ $t("analyse.download_result") }}</el-dropdown-item
                    >
                    <el-dropdown-item
                      v-if="scope.row.label_image_path && user.role == '0'"
                      @click.native="downloadVoiEditResult(scope.row)"
                      ><i class="fa fa-download text-success"></i>
                      {{ $t("analyse.download_label") }}</el-dropdown-item
                    >

                    <el-dropdown-item
                      divided
                      @click.native="deleteAnalyse(scope.row.id)"
                      ><i class="fa fa-trash"></i>
                      {{ $t("delete") }}</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>

                <!-- 
                <div class="dropdown d-inline-block dropleft">
                  <div class="cursor" @click.stop="showMenu(scope.row)">
                    <i class="fa fa-bars mr-1"></i>
                    <i class="fa fa-angle-down"></i>
                  </div>
                  <div
                    class="dropdown-menu shadow-lg"
                    :class="{ show: scope.row.id === rowId }"
                  >
                    <div class="dropdown-header">{{ $t("operate_menu") }}</div>
                    <div
                      class="dropdown-item cursor"
                      v-if="
                        scope.row.status === '3' &&
                        scope.row.download_type.statistic
                      "
                      @click="downloadStatistic(scope.row.id)"
                    >
                      <i class="fa fa-download text-success"></i>
                      {{ $t("analyse.download_statistic") }}
                    </div>
                    <div
                      class="dropdown-item cursor"
                      v-if="
                        scope.row.status === '3' &&
                        scope.row.download_type.result &&
                        user.role == '0'
                      "
                      @click="downloadResult(scope.row.id)"
                    >
                      <i class="fa fa-download text-success"></i>
                      {{ $t("analyse.download_result") }}
                    </div>

                    <div
                      v-if="scope.row.label_image_path && user.role == '0'"
                      class="dropdown-item cursor"
                      @click="downloadVoiEditResult(scope.row)"
                    >
                      <i class="fa fa-download"></i>
                      下载VOI编辑结果
                    </div>

                    <div class="dropdown-divider"></div>
                    <div
                      class="dropdown-item cursor text-danger"
                      @click="deleteAnalyse(scope.row.id)"
                    >
                      {{ $t("delete") }}
                    </div>
                  </div>
                </div> -->
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="clearfix mt-3 mb-3">
        <div class="float-left">
          <pagination
            v-if="totalCount > 0"
            :pageSize="pageSize"
            :layout="layout"
            :currentPage="currentPage"
            :total="totalCount"
            @goToPage="handleCurrentChange"
          />
        </div>
        <!-- <div class="float-right">
          <button type="button" class="btn btn-primary btn-sm" @click.stop="batchDownloadData">
            <i class="fa fa-download mr5"></i>
            {{$t('_data.batch_download')}}
          </button>
          <button type="button" class="btn btn-info btn-sm ml-3" @click.stop="showAddPopover = true">
            <i class="fa fa-people-carry mr5"></i>
            {{$t('_data.add_to_pro')}}
          </button>
        </div> -->
      </div>
    </div>
    <div class="empty-wrapper" :class="showSearchDrawer ? 'active' : ''"></div>
    <div class="search-drawer" :class="showSearchDrawer ? 'active' : ''">
      <p class="top-line clearfix">
        <span>高级搜索</span>
        <span class="float-right close-search" @click="hideSearchWrapper"
          >关闭</span
        >
      </p>
      <div class="drawer-body">
        <div class="block">
          <span class="demonstration">上传时间</span>
          <el-date-picker
            v-model="value1"
            type="daterange"
            size="mini"
            :clearable="clearable"
            prefix-icon=" "
            range-separator="—"
            start-placeholder
            end-placeholder
          ></el-date-picker>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  analyseRequest,
  deleteAnalyse,
  ruleRequest,
  downloadAnalysisData,
  getVoiViewAddress,
  downloadVoiResult,
  getApplication,
} from "../../api/api";
import pagination from "../../components/pager";
import { paginationMixin } from "../../utils/mixin";
import { MessageBox, Loading } from "element-ui";

import { mapState, mapGetters } from "vuex";
import { eventHub } from "@/eventHub.js";

let _that;

export default {
  mixins: [paginationMixin],
  components: {
    pagination,
  },
  data() {
    return {
      searchForm: {
        search: this.$store.state.analysisParams.search || "",
        series: this.$store.state.analysisParams.series || "",
      },
      modality: this.$store.state.analysisParams.modality || "all",
      seriesList: new Array(),
      dataList: new Array(),
      appModality: new Array(),
      showSearchDrawer: false,
      value1: "",
      clearable: false,
      rowId: "",
      user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : {},
      token: localStorage.getItem("token") || "",
      subject_id: this.$route.query.subject_id || "",
      app_id: this.$route.query.app_id || "",
      currentPage: parseInt(this.$route.query.offset) || 1,
      pageSize: 15,
      totalCount: 0,
      timer: new Date().getTime(),
      appList: [],
      //lang: localStorage.getItem("lang") || "zh",
    };
  },
  computed: {
    ...mapState({
      lang: (state) => state.language,
      url_voiviewer: (state) => state.url_voiviewer, //:28803
      url_backendapi: (state) => state.url_backendapi, //:28801
      url_adaptorapi: (state) => state.url_adaptorapi, //:28000
    }),
  },
  created() {
    this.getSeriesList();
    this.getAnalyseList();
    this.getAppList();
  },
  methods: {
    handleCurrentSearch() {
      this.currentPage = 1;
      this.getAnalyseList();
    },
    handleAppChange() {
      this.searchForm.series = "";
      this.currentPage = 1;
      this.getAnalyseList();
    },
    handleSeriesChange() {
      this.currentPage = 1;
      this.getAnalyseList();
    },
    showSearchWrapper() {
      this.showSearchDrawer = true;
    },
    hideSearchWrapper() {
      this.showSearchDrawer = false;
    },
    async getAnalyseList() {
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });
      let modality = this.modality === "all" ? "" : this.modality;
      let params = {
        ...this.searchForm,
        modality: modality,
        subject_id: this.subject_id,
        app_id: this.app_id,
        limit: this.pageSize,
        offset: this.currentPage - 1,
      };

      this.$store.state.analysisParams = params;
      try {
        let res = await analyseRequest(params);
        if (res.status === 200) {
          this.dataList = res.data.length > 0 ? res.data : [];
          this.totalCount = res.total;
          this.appModality = res.app_modality || [];
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    async getAppList() {
      let params = {
        modality: this.modality === "all" ? "" : this.modality,
      };
      try {
        let res = await getApplication(params);
        this.appList = res.data;
      } catch (error) {
        this.$helper.errNotify(error.message);
      }
    },
    async getSeriesList() {
      let params = {
        request: "series_list",
        userid: this.$store.state.userid,
        modality: this.modality === "all" ? "" : this.modality,
      };
      try {
        let res = await ruleRequest(params);
        if (res.status === 200) {
          this.seriesList = res.data ? res.data : [];
        } else {
          this.seriesList = [];
        }
      } catch (error) {
        this.$helper.errNotify(error.message);
      }
    },
    changeModality() {
      this.searchForm.series = "";
      this.currentPage = 1;
      this.getSeriesList();
      this.getAnalyseList();
      this.getAppList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getAnalyseList();
    },
    formatGender: function (row) {
      return row.subject_gender === "0"
        ? this.$t("male")
        : row.subject_gender === "1"
        ? this.$t("female")
        : "";
    },
    statusColClass: function (status) {
      switch (status) {
        case "0":
        case "1":
          return "text-muted";
        case "2":
          return "text-primary";
        case "3":
          return "text-success";
        default:
          return "text-danger";
      }
    },
    showMenu(rowData) {
      this.rowId = rowData.id;
    },
    hideMenu() {
      this.rowId = "";
    },
    deleteAnalyse(id) {
      MessageBox.confirm(this.$t("notice.p_confirm"), this.$t("notice.tips"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      })
        .then(() => {
          this.deleteAnalyseFunc(id);
        })
        .catch(() => {});
    },
    async deleteAnalyseFunc(id) {
      this.rowId = "";
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });

      try {
        let res = await deleteAnalyse({
          id: id,
        });
        if (res.status === 200) {
          this.$helper.successNotify(res.message);
          this.getAnalyseList();
        } else {
          this.$helper.errNotify(res.message);
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    async downloadStatistic(id) {
      let params = {
        id: id,
        type: "statistic",
      };
      try {
        let res = await downloadAnalysisData(params);
        if (res.status === 200) {
          this.$helper.successNotify(res.message);
          window.open(res.data);
        } else {
          this.$helper.errNotify(res.message);
        }
      } catch (error) {
        this.$helper.errNotify(error.message);
      }
    },
    async downloadResult(id) {
      let params = {
        id: id,
        type: "result",
      };
      try {
        let res = await downloadAnalysisData(params);
        if (res.status === 200) {
          this.$helper.successNotify(res.message);
          window.open(res.data);
        } else {
          this.$helper.errNotify(res.message);
        }
      } catch (error) {
        this.$helper.errNotify(error.message);
      }
    },

    async downloadVoiEditResult(task) {
      const me = this;

      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { id } = this.user;
      let params = {
        base_image_path: task.base_image_path,
        label_image_path: task.label_image_path,
        downloadtype: "2",
        keyid: task.task_id,
        from: "brainlabel",
        subject_name: task.subject_number,
      };
      try {
        let res = await downloadVoiResult(params);
        if (res.status === 200) {
          //window.location.href = res.result; //zip url;

          this.$helper.successNotify(res.message);
          eventHub.$emit("getProcessList");
        } else {
          this.$helper.errNotify(res.message);
        }

        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    async getVoiAddress(rowData) {
      let params = {
        id: rowData.id,
        from: "brainlabel",
        language: this.lang, //localStorage.getItem("lang") || "zh",
      };
      try {
        let res = await getVoiViewAddress(params);
        if (res.status === 200) {
          window.open(res.data);
        } else {
          this.$helper.errNotify(res.message);
        }
      } catch (error) {
        this.$helper.errNotify(error.message);
      }
    },
  },
  mounted() {
    window.addEventListener("click", this.hideMenu, false);
  },
  beforeCreate() {
    _that = this;
  },
  filters: {
    formatStatus: function (status) {
      switch (status) {
        case "0":
          return _that.$t("task_status.created");
        case "1":
          return _that.$t("task_status.wait");
        case "2":
          return _that.$t("task_status.processing");
        case "3":
          return _that.$t("task_status.completed");
        case "4":
          return _that.$t("task_status.failed");
        case "5":
          return _that.$t("task_status.timeout");
        case "6":
          return _that.$t("task_status.license_expired");
        case "7":
          return _that.$t("task_status.license_not_found");
        case "8":
          return _that.$t("task_status.license_error");
        case "12":
          return _that.$t("task_status.invalid");
        default:
          return " ";
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("click", this.hideMenu);
  },
};
</script>

<style lang="less">
.table {
  border-bottom: 1px solid #dee2e6;
}

.data-manage {
  .main-content {
    transition: 0.3s ease-in;
  }

  .main-content.active {
    width: calc(~"100% - 220px");
    // transform: translate(-220px);
    // margin-right: 220px;
  }

  .el-checkbox {
    margin-bottom: 0;
    // color: #fff;
  }

  .el-table .cell {
    overflow: visible;
  }

  .el-table {
    overflow: visible;
  }

  .el-table__body-wrapper {
    overflow: visible !important;
  }
}

.data-manager-popover {
  .popover-content {
    margin: 120px auto 0;
    width: 700px;
    background: #fff;
  }
}

.dialog-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2003;
  overflow: auto;
  margin: 0;
  background: rgba(0, 0, 0, 0.6);

  .dialog-content {
    position: relative;
    margin: 15vh auto;
    border-radius: 3px;
    width: 600px;
    background: #fff;
  }

  .dialog-header {
    padding: 20px 10px;
    border-bottom: 1px solid #dee2e6;
  }

  .dialog-title {
    line-height: 24px;
    font-size: 18px;
    color: #303133;
  }

  .dialog-headerbtn {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 0;
    background: 0 0;
    border: none;
    outline: 0;
    cursor: pointer;
    font-size: 16px;
  }

  .dialog-body {
    padding: 30px 0;
    text-align: center;
  }
}

.bg-span {
  display: inline-block;
  padding: 0 5px;
  height: 18px;
  line-height: 18px;
  border-radius: 4px;
  font-size: 12px;
  transform: scale(0.8);
  margin: 0 1px 0 0;
}
</style>
